body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  background-color: black;
}

.source {
  font-family: '', monospace !important;
}

.pink {
  color: #FF66D4;
  font-weight: 800;
}

.cyber-blue {
  color: #05FFFF;
  font-weight: 800;
}

.extra-bold {
  font-weight: 800;
}

.desktop {
  p {
    line-height: 1.3em;
  }

  .medium {
    font-size: 1.5em;
  }
}

.mobile text {
  font-size: 1.25em;
  line-height: 1.5em;
}

.mobile {
  .medium {
    font-size: 1.25em;
  }
}

.guide {
  width: 70%;
}

.bold {
  font-weight: 700 !important;
}

.isaac {
  color: #5DE86F;
  font-weight: 800;
}

.marina {
  color: #FF5811;
  font-weight: 800;
}

.emi {
  color: #FBAB20;
  font-weight: 800;
}

.alma {
  color: #008AFF;
  font-weight: 800;
}

.white-link {
  color: white;
  text-decoration: underline;
}

.blue-link {
  color: #008AFF;
  text-decoration: underline;
}

.card-link {
  color: #FBAB20;
  text-decoration: underline;
}