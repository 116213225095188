.AlmaProject {
    text-align: center;
    justify-content: center;
    background-color: black ;
    font-family: 'Source Code Pro';

    div {
      font-family: 'Source Code Pro';
    }

    p {
      font-family: 'Source Code Pro';
    }

    h1 {
      font-family: 'Source Code Pro';
    }
  }
  
  .AlmaProject-Container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }