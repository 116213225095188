
.logo-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 15vh 0 2vh;
    background-color: black;

    .logo {
        width: 33vw;
        height: auto;
    }
}

.logo-container-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8vh 0 2vh;
    background-color: black;

    .logo {
        width: 75vw;
        height: auto;
    }
}

