
.fishbowl-footer {
    margin-top: -7vw;
    width: 100%;

    img {
        width: 100%;
    }
}

.fishbowl-footer-mobile {
    margin-top: -7vw;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
        width: 150vw;
    }
}