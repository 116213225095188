
.project-footer {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 4vw;

    .project-footer-image {
        margin-bottom: 4vw;
        width: 25vw;

        img {
            width: 100%;
        }
    }

    .project-footer-buttons {
        display: flex;
        direction: row;
        height: 12vw;
        justify-content: space-between;
        // width: 30vw;

        .social-media {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            width: 100%;
            padding: 0 2vw;
            // margin-right: 5vw;

            a {
                width: auto;
                margin: 0 0.5vw;
            }

            img {
                height: 3.5vw;
            }

            .social-media-container {
                display: flex;
            }
        }

        .logo-button {
            height: 100%;

            img {
                height: 100%;
            }
        }
    }

    .social-media-button {
        display: flex;
        height: 12vw;
        max-height: 45px;
        color: white;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;
        width: auto;
    
        .icon {
            width: 3vw;
            height: auto;

            img {
                object-fit: fill;
                height: 100%;
                width: 100%;
            }
        }
    
        .text {
            width: 10em;
            color: #005ECE;
            margin-left: 1vw;
            margin-bottom: 0.25vw;
            font-size: 1.5vw;
            height: 100%;
            letter-spacing: 0.1em;
            font-family: "Prompt", sans-serif;
            font-weight: 500;
        }
    }
}




.project-footer-mobile {
    width: 100%;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: 115% !important;
    background-position: 0% 80%;
    height: 100%;
    padding: 2vh 0 0 0;


    .project-footer-image {
        margin-bottom: 4vw;
        width: 40vw;

        img {
            width: 100%;
        }
    }

    .logo-button {
        margin-top: 4vw;
        margin-bottom: 4vw;
        width: 25vw;

        img {
            width: 100%;
        }
    }

    .social-media-container {

        margin-bottom: 6vh;

        .social-media-button {
            background-color: #FFFFFF;
            display: flex;
            width: 60vw;
            padding: 1vh 1vw;
            color: white;
            border-radius: 10vw;
            align-items: center;
            justify-content: space-evenly;
            cursor: pointer;
            margin-top: 3vh;
        
            .icon {
                max-width: 6vw;
                height: 6vw;

                img {
                    object-fit: fill;
                    height: 100%;
                    width: 100%;
                }
            }
        
            .text {
                color: black;
                margin-left: 1vw;
                margin-bottom: 0.25vw;
                font-size: 4.5vw;
                height: 100%;
                letter-spacing: 0.1em;
                font-family: "Prompt", sans-serif;
                font-weight: 500;
            }
        }
    }

}