
.project-about {
    font-family: "Prompt", sans-serif;
    color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-weight: normal;
    }

    p {
        text-align: center;
        max-width: 28em;
    }

    .full-width-image {
        width: 85%;
        margin-top: 2.5vh;

        img {
            width: 100%;
        }
    }
}

.mobile-project-about {
    font-family: "Prompt", sans-serif;
    color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vw;

    h1 {
        font-weight: normal;
        font-size: 1em;
    }

    p {
        text-align: center;
        max-width: 28em;
    }

    .full-width-image {
        margin-top: 5vw;

        img {
            width: 100%;
        }
    }
}