
.project-image-description {
    font-family: "Prompt", sans-serif;
    color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vw;
    margin-top: 6vw;

    h1 {
        font-weight: normal;
    }

    p {
        margin-top: 4vw;
        text-align: center;
        max-width: 38em;
        line-height: 2em;
    }

    h4 {
        font-style: italic;
        font-size: 1.25em;
    }

    .project-image-container {
        width: 60%;
        display: flex;
        height: 23vw;
        align-items: start;
        overflow: hidden;
        margin-top: 1vw;

        img {
            width: 100%;
            height: auto;
            margin-top: -4vw;
        }
    }
}


.mobile-project-image-description {
    font-family: "Prompt", sans-serif;
    color: white;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vw;
    margin-top: 6vw;

    h1 {
        font-weight: normal;
        font-size: 1em;
    }

    p {
        margin-top: 4vw;
        text-align: center;
        max-width: 24em;
        line-height: 2em;
        font-size: 0.75em;
    }

    h4 {
        max-width: 16em;
        font-style: italic;
        font-size: 1em;
    }

    .project-image-container {
        width: 60%;
        display: flex;
        height: 23vw;
        align-items: start;
        overflow: hidden;
        margin-top: 1vw;

        img {
            width: 100%;
            height: auto;
            margin-top: -4vw;
        }
    }
}