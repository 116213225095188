.App {
  text-align: center;
  justify-content: center;
  background-color: black ;
  font-family: 'Prompt';
}

.App-Container {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration: none;
}

.desktop {
  .text-big {
    font-size: 2.34em;
    font-weight: bold;
    font-family: 'Prompt', sans-serif;
  }
}

.mobile{
  .text-big {
    font-size: 6vw;
    font-weight: bold;
    font-family: 'Prompt', sans-serif;
  }

  .text {
    font-size: 1em !important;
    line-height: 1.5em;
  }
}


html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  max-width: 100%;
}

.desktop {
    .text {
      font-size: 1em;
      line-height: 1.5em !important;
    }
}