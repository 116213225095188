
.alma-banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0vh 0 2vh;
    margin-top: 1.5vw;
    background-color: black;

    .imageSizer {
        opacity: 0;
    }
    
    .bannerContainer 
    {
        box-shadow: inset 0em 0em 7em 5em black;
        height: 100%;
        position: relative;
        background-size: cover;
    }

    .alma-title {
        width: 32vw;
        margin-top: -27vw;
        z-index: 10;
    }
}

.mobile-alma-banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0vh 0 4vh;
    background-color: black;
    margin-bottom: 4vw;
    margin-top: 18vw;

    .imageSizer {
        opacity: 0;
    }
    
    .bannerContainer 
    {
        position: relative;
        background-size: cover;
        display: flex;
        box-shadow: inset 0em 0em 1.5em 0.5em black;

        img {
            width: 100%;
        }
    }

    .alma-title {
        width: 55vw;
        margin-top: -20vw;
        z-index: 10;
    }
}

