
.project-horizontal-section {
    color: white;
    font-family: "Prompt", sans-serif;
    display: flex;
    padding: 0.5vw;
    width: 58%;

    .section-info {
        flex: 1;
        margin-top: 2vw;
        text-align: left !important;
        height: 100%;
        justify-content: center;
        align-items: center;

        h1 {
            font-size: 1.25em;
            text-align: center;
        }

        p {
            text-align: center;
            line-height: 1.5em;
            max-width: 17em;
        }

        .info-text {
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .section-image {
        flex: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;

        img {
            width: 100%;
            object-fit: cover;
        }
    }
}

.info2 {
    color: white;
    font-size: 2em !important;
    text-align: center;
    margin-bottom: 2vw;
    margin-top: 4vw;
}


.mobile-hangout {
    height: auto;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 5vw 0;
    width: 75%;
    font-family: "Prompt", sans-serif;

    h1 {
        font-weight: normal;
        margin-top: 8vw;
        font-weight: 800 !important;
        font-size: 1em !important;
    }

    p {
        line-height: 2em;
        max-width: 24em;
    }

    .section-image {
        flex: 3;
        width: 80%;
        overflow: hidden;
        display: flex;

        img {
            width: 100%;
            object-fit: cover;
        }
    }
}