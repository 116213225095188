
.desktop-projects {
    margin-top: 12vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .project-title {
        max-width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }

    .image-swiper {
        max-width: 100vw;
        margin-top: 5vh;
    }

    .image-cut {
        max-height: 60vh;
        max-width: 100%;
        display: flex;
        justify-content: center;
    
        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .project-close {
        margin-top: 5vh;
        margin-bottom: 8vh;
    
        .soon-text {
            font-size: 3vw;
            font-weight: bold;
            font-family: 'Prompt', sans-serif;
        }
    
        .pun-text {
            font-size: 1vw;
            font-weight: bold;
            font-style: italic;
            color: #64B9FF;
            font-family: 'Prompt', sans-serif;
        }
    }
}

.mobile-projects {
    margin-top: 8vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .project-title {
        max-width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }

    .image-swiper {
        max-width: 100vw;
        margin-top: 4vh;
    }

    .image-cut {
        display: flex;
    
        img {
            object-fit: none;
            width: 100%;
            height: 45vh;
        }
    }

    .project-close {
        margin-top: 5vh;
        margin-bottom: 8vh;
    
        .soon-text {
            font-size: 6vw;
            font-weight: bold;
            font-family: 'Prompt', sans-serif;
        }
    
        .pun-text {
            font-size: 3vw;
            font-weight: bold;
            font-style: italic;
            color: #64B9FF;
            margin-top: 0.5vh;
            font-family: 'Prompt', sans-serif;
        }
    }
}

.orange-text {
    color: #FF622B;
}

.right-text {
    margin-left: 0.25em;
}

.swiper-pagination-bullet-active {
    background-color: white !important;
}