
.team-title {
    color: white;
    font-size: 2.5em;
    margin-right: 5vw;
}

.team-title-mobile {
    margin-top: 2vh;
    color: white;
    font-size: 6vw;
}

.team-members {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .vertical-section {
        display: flex;
        flex-direction: row;
        width: 75%;
        align-items: center;
        justify-content: center;
        margin-bottom: 3vw;
    }

    .team-member-container {
        height: 40vh;
        align-items: center;
        background-size: 100% 100% !important;
        display: flex;
        flex-direction: column;

        .filler {
            flex: 2.5;
            width: auto;
            clear: both;
        }

        .member {
            flex: 6 1;
            display: flex;
            flex-direction: column;
            margin-top: 3%;

            .member-info {
                flex: 3 1;
                height: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;

                .member-name {
                    font-size: 1.5vw;
                    margin-top: 8%;
                }

                .member-role {
                    color: white;
                    margin-top: 2%;
                    max-width: 11em;
                }

                .member-cardd {
                    color: #FF9800;
                    text-decoration: underline;
                    margin: 1vw 0;
                }

                .more-info {
                    display: flex;
                    flex-direction: row;
            
                    div {
                        background-color: #EEE8F4;
                        width: 10vw;
                        border-radius: 15vw;
                        padding: 0.25vw;
                        text-align: center;
                        box-shadow: 0vw 0.5vw 0.5vw -0.5vw #07070780;
                        margin-top: 0.5vh;
                        margin-bottom: -1vh;
                    }
                }
            }
    
            .member-image {
                flex: 2;
                padding: 0vw 3vw;
                img {
                    object-fit: cover;
                    width: 100%;
                }
            }

        }

    }

}

.team-members-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1.5vh;
}

.about-text-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 20em;
    text-align: center;
    color: white;
}

.about-text {
    width: 39vw;
    text-align: left;
}

.about-text-mobile {
    font-family: 'Prompt', sans-serif;
    width: 90vw;
    margin-bottom: 8vw;
}

.p1 {
    margin-top: 4vh;
}

.p2 {
    margin-top: 2vw;
    margin-bottom: 4vh;
}

.the-team {
    display: flex;
    justify-content: space-evenly;
    margin-top: 2vw;
}

.member {
    display: flex;
    // padding: 0px 0vw 0px 12vw;
    max-width: 46vw;
    align-items: center;
}

.member-low {
    margin-top: 4vh;
}

.the-team-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.team-member-mobile {
    border-radius: 5%;
    width: 56vw;
    text-align: center;
    padding: 2vh 10vw;
    font-family: "Prompt", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;

    .member-image {
        flex: 2;
        padding: 0vw 3vw;
        img {
            object-fit: cover;
            width: 100%;
        }
    }

    .member-cardd {
        color: #FF9800;
        text-decoration: underline;
        margin: 1vw 0;
    }
    
    .member-name {
        font-size: 5.5vw;
        color: white;
    }
    
    .member-role {
        color: white;
        max-width: 11em;
    }

    .member-description {
        color: white;
    }

    .more-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        font-size: 3.5vw;

        div {
            background-color: #EEE8F4;
            width: 32vw;
            border-radius: 15vw;
            padding: 1vw;
            text-align: center;
            box-shadow: 0vw 1vw 3vw -1.5vw;
            margin-top: 2vh;
            margin-bottom: -1vh;
        }
    }
}