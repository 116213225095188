
.project-vertical-section {
    color: white;
    display: table-cell;
    padding: 0.5vw;

    .section-info {
        flex: 1;
        margin-top: 2vw;
        text-align: left !important;
        height: 100%;

        h1 {
            font-size: 1.25em;
            text-align: center;
        }

        p {
            text-align: center;
            line-height: 1.5em;
            max-width: 15em;
        }

        .info-text {
            justify-content: center;
            display: flex;
        }
    }

    .section-image {
        flex: 1;
        max-height: 22vw;
        overflow: hidden;
        display: flex;
        justify-content: center;

        img {
            height: 22vw;
            object-fit: cover;
        }
    }
}


.mobile-project-vertical-section {
    height: auto;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 5vw 0;
    width: 75%;

    h1 {
        font-weight: normal;
        margin-top: 8vw;
        font-weight: 800 !important;
        font-size: 0.8em !important;
    }

    p {
        line-height: 2em;
        max-width: 24em;
        margin: 0;
    }

    .info-text {
        justify-content: center;
        display: flex;
        width: 14em;
    }

    .section-image {
        flex: 3;
        width: 100%;
        max-height: 42vw;
        overflow: hidden;
        display: flex;
        justify-content: center;

        img {
            height: 42vw;
            object-fit: cover;
        }
    }
}