
.alma-social-media-container {
    
    display: flex;
    flex-direction: column;
    font-family: "Prompt", sans-serif;

    .row {
        height: 20vw;
        justify-content: center;
        align-items: center;

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .social-image {
                display: flex;
                text-align: center;
                margin-bottom: 2vw;
                justify-content: center;
                align-items: center;

                img {
                    flex: 1;
                    width: 6vw;
                    height: auto;
                    margin: 0 1vw;
                }
            }

            .social-text {
                color: white;
                font-size: 2vw;
                width: 14vw;
                letter-spacing: 0.25vw;
            }
        }
    }
}

.alma-social-media-container-mobile {
    
    display: flex;
    flex-direction: column;
    font-family: "Prompt", sans-serif;
    margin-bottom: 10vw;

    .row {
        height: 20vw;
        justify-content: center;
        align-items: center;

        .info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .social-image {
                display: flex;
                text-align: center;
                margin-bottom: 2vw;
                justify-content: center;
                align-items: center;

                img {
                    flex: 1;
                    width: 12vw;
                    height: auto;
                    margin: 0 1vw;
                }
            }

            .social-text {
                color: white;
                font-size: 2.5vw;
                width: 14em;
                letter-spacing: 0.25vw;
            }
        }
    }
}