
.full-width {
    width: 100%;
}

.nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 8vh;
    align-items: center;
    box-shadow: -1vw -3.5vw 5vw #070707;
    z-index: 99999;
}

.nav-bar-options {
    display: flex;
    font-weight: bold;
    width: auto;
    justify-content: space-between;
    font-size: 1.25vw;
    padding: 1vw;
}

.mobile-nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: black;
    height: 8vh;
    align-items: center;
    box-shadow: -1vw -3.5vw 5vw #070707;
    z-index: 99999;
}

.mobile-nav-bar-options {
    display: flex;
    font-weight: bold;
    width: 100%;
    justify-content: space-between;
    font-size: 3.5vw;
    padding: 0 5vw;
}

.option {
    color: #000000;
    cursor: pointer;
    margin: 0vw 1vw;
}

.mobile-option {
    width: 100%;
    text-align: center;
}

.nav-bar-alma-project {
    background-color: black;
    
    .option {
        color: white;
    }

    .option-selected {
        color: cyan !important;
    }


}

.nav-bar-default {
    background-color: black;

    .option {
        color: white;
    }

    .option-selected {
        color: #FF622B !important;
    }


}