
.water-container {
    margin: 5vh 0 0 0;
    width: 100%;
}

.water-top {
    width: 100%;

    img {
        width: 100%;
    }
}

.under-water {
    margin-top: -0.5vw;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}